<template>
  <nav class="navbar" :class="{'responsive-nav': isResponsiveNavOpen}">
    <div class="navbar_left">
      <img v-if="logo" :src="logo" alt="Logo" class="navbar_logo" />
      <span v-else>{{ name }}</span>
    </div>
    <div class="navbar_right">
      <ul :class="{'show-links': isResponsiveNavOpen}">
        <li><a href="#" @click.prevent="scrollToAcceuil">Accueil</a></li>
        <li><a href="#" @click.prevent="scrollToServices">Services</a></li>
        <li><a href="#" @click.prevent="scrollToSite">Réalisations</a></li>
        <li><a href="#" @click.prevent="scrollTocontact">Contact</a></li>
        <li>
          <button class="nav_button">
            <a class="nav_button" href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0LtkF-ZHf-gtQxlbGX2oAZV1xvJFS6VlG6FklypbyyFGfmS2ntH2X5bJ7tYOCWpFvtg1fOnSSR" target="_blank">
              Prendre RDV !
            </a>
          </button>
        </li>
      </ul>
      <div class="navbar_toggler" @click="toggleResponsiveNav">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
props: {
  name: {
    type: String,
    required: false,
  },
  logo: {
    type: String,
    required: false,
  },
},
methods: {
  scrollToAcceuil() {
    const homeSection = document.getElementById('home');
    if (homeSection) {
      homeSection.scrollIntoView({ behavior: 'smooth' });
    }
  },
  scrollToServices() {
    const servicesSection = document.getElementById('services');
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: 'smooth' });
    }
  },
  scrollToSite() {
    const siteSection = document.getElementById('site');
    if (siteSection) {
      siteSection.scrollIntoView({ behavior: 'smooth' });
    }
  },
  scrollTocontact() {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  },
  toggleResponsiveNav() {
    this.isResponsiveNavOpen = !this.isResponsiveNavOpen;
  },
},
};
</script>

<style scoped>
.navbar_logo {
    max-height: 300px; /* Taille du logo */
    height: auto; /* Préserve le ratio */
}
</style>
