<template>
  <div class="contact-section">
    <h2>{{ heading }}</h2>
    <p>
      <strong>{{ teamHighlight }}</strong> {{ description }}
    </p>

    <div class="contact-details">
      <a :href="'tel:' + phoneNumber" class="contact-phone">
        {{ phoneNumber }}
      </a>
      <a
        :href="appointmentLink"
        class="appointment-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ buttonText }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      heading: "VOUS AVEZ UN PROJET ?",
      teamHighlight: " Je vous propose",
      description:
        " un rendez-vous pour discuter de vos projets et idées, ou un appel bref et productif pour les concrétiser efficacement.",
      phoneNumber: "06 59 56 11 65", 
      buttonText: "Prendre RDV", 
      appointmentLink:
        "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0LtkF-ZHf-gtQxlbGX2oAZV1xvJFS6VlG6FklypbyyFGfmS2ntH2X5bJ7tYOCWpFvtg1fOnSSR", 
    };
  },
};
</script>

<style src="../assets/css/ContactSection.css"></style>
