<template>
  <a 
    :href="link" 
    target="_blank" 
    rel="noopener noreferrer" 
    class="card" 
    :style="{ backgroundImage: `url(${background})` }">
    <div class="overlay"></div>
    <img class="logo" :src="logo" alt="logo" />
    <div class="content">
      <h3>{{ title }}</h3>
      <div class="tags">
        <span v-for="(tag, index) in tags" :key="index" class="tag">{{ tag }}</span>
      </div>
      <p>{{ description }}</p>
    </div>
  </a>
</template>

<script>
export default {
  name: "Card",
  props: {
    logo: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      required: true,
    },
    background: {
      type: String,
      required: false,
      default: "none",
    },
    link: {
      type: String,
      required: true, 
    },
  },
};
</script>

<style src="../assets/css/card.css"></style>
