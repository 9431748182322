<template>
  <footer class="footer">
    <p>{{ copyright }}</p>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      copyright: "© " + new Date().getFullYear() + " Concept Génération",
    };
  },
};
</script>

<style>

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px;
  /* background-color: #f7f0f0; */
  /* background: linear-gradient(90deg, #e3f2fd, #f9fcff); */
  background: linear-gradient(90deg, #f9fcff, #e3f2fd);

  letter-spacing: 3px;
  line-height: 35px;
  font-weight: 700;
}

</style>
