<template>
    <div class="social-sidebar">
      <a v-for="(icon, index) in socialLinks" :key="index" :href="icon.link" target="_blank" rel="noopener noreferrer">
        <img :src="icon.icon" :alt="icon.name" class="social-icon" />
      </a>
    </div>
  </template>
  
  <script>
  export default {
    name: "SocialSidebar",
    data() {
      return {
        socialLinks: [
          { name: "Instagram", icon: require("@/assets/icons/instagram.png"), link: "https://www.instagram.com/concept_generation/?hl=fr" },
          { name: "Facebook", icon: require("@/assets/icons/facebook-f-brands-solid.svg"), link: "https://www.facebook.com/profile.php?id=61560926565208" },
          { name: "Linkedin", icon: require("@/assets/icons/linkedin-in-brands-solid.svg"), link: "https://www.linkedin.com/in/valentin-gaultier-8766b7199/" },
          { name: "Email", icon: require("@/assets/icons/email.png"), link: "mailto:valentin.gaultier41@gmail.com" },
  
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .social-sidebar {
    position: fixed;
    right: 20px; 
    top: 50%; 
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    z-index: 1000;
  }
  
  .social-icon {
    width: 20px; 
    height: 20px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }
  
  .social-icon:hover {
    transform: scale(1.2); 
    opacity: 0.8;
  }
  </style>
  